import React from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';

import Article from '../components/News/Article';
import { useSiteContext } from '../components/SiteContext';
import withApollo from '../lib/apollo';
import throw404 from '../lib/throw404';
import articleByPathQuery from '../lib/queries/news/articleByPathQuery.graphql';
import { articleImageConfigurations } from '../lib/imageConfigurations';
import getNewsboardFieldVariables
from '../lib/queries/getNewsboardFieldVariables';
import redirectToRoute from '../lib/redirectToRoute';
import hasServerLocalNews from '../lib/hasLocalNews';
import getArticlesParameters from '../lib/getArticlesParameters';
import { getRedirectLocation } from '../components/Redirect';
import getPrefix from '../lib/getPrefix.mjs';
import getUrlFromRequest from '../lib/getUrlFromRequest.mjs';
import getArticleField from '../lib/queries/news/getArticleField';
import getWindowUrl from '../lib/getWindowUrl.mjs';

const NewsArticle = ({ router }) => {
  const {
    hasLocalNews,
  } = useSiteContext();

  const { id } = router.query;

  if (!id && !hasLocalNews) {
    return throw404();
  }

  const articleProperties = { id: Number(id) };

  return (
    <Article {...articleProperties} />
  );
};

NewsArticle.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

NewsArticle.defaultProps = {
  router: {},
};

NewsArticle.getInitialProps = async (context) => {
  const {
    query: { id }, apolloClient, req, res, asPath,
  } = context;

  if (!id) {
    const url = req ? getUrlFromRequest(req) : getWindowUrl();
    const path = asPath.split('?')[0].replace(/^\//, '');

    if (!req || req?.headers['x-redirect-test'] !== 'true') {
      const redirectLocation = await getRedirectLocation(url);

      if (redirectLocation) {
        return redirectToRoute(redirectLocation, {}, res);
      }
    }

    const prefix = getPrefix(url);
    const hasLocalNews = hasServerLocalNews(prefix);
    const field = getArticleField(prefix, hasLocalNews, !id);

    const {
      data: { [field]: article },
    } = await apolloClient.query({
      query: articleByPathQuery,
      variables: {
        ...getNewsboardFieldVariables(
          prefix, hasLocalNews,
        ),
        path,
        imageConfigurations: articleImageConfigurations,
      },
      fetchPolicy: 'network-only',
    });

    if (!article && res?.status) {
      return throw404();
    }

    const articleParameters = getArticlesParameters(
      article,
    );

    return redirectToRoute('article', {
      ...articleParameters,
    }, res);
  }

  return {};
};

export const NewsArticleWithRouter = withRouter(NewsArticle);

NewsArticleWithRouter.getLayout = (page) => page;

export default withApollo(NewsArticleWithRouter);
