import React, { useState, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useApolloClient, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import EmailAccessForm from '@fuww/library/src/Users/EmailAccessForm';
import Subtitle2 from '@fuww/library/src/Typography/SubTitle2';
import { enqueueSnackbar } from 'notistack';
import { useCaptcha } from '../Captcha';
import onLogin from '../../lib/onLogin';
import messages from '../../lib/messages.mjs';
import loginMutation from '../../lib/mutations/login.graphql';
import registerMutation from '../../lib/mutations/register.graphql';
import { saveRouterState } from '../../lib/routerState';
import { handleUserRegistration } from './Signup';
import LinkAndAnchor from '../LinkAndAnchor';
import { useSiteContext } from '../SiteContext';

const emailAccessPropertyTypes = {
  setVerifyEmailMessage: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

type EmailAccessProperties = InferProps<
typeof emailAccessPropertyTypes
>;

const EmailAccess = ({
  setVerifyEmailMessage,
  onSubmit,
}: EmailAccessProperties) => {
  const intl = useIntl();
  const router = useRouter();
  const { locale } = useSiteContext();
  const [registeredAs, setRegisteredAs] = useState<string | undefined>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const client = useApolloClient();
  const { executeRecaptcha } = useCaptcha();

  const [createSession] = useMutation(loginMutation, {
    onCompleted: (data) => {
      onLogin(
        data,
        setSubmitDisabled,
        intl.formatMessage(messages['login.successHeadline']),
        client,
        'InPage',
      );
    },
    onError: () => {
      setSubmitDisabled(false);
    },
  });

  const [registerUser] = useMutation(registerMutation, {
    onCompleted: () => {
      setSubmitDisabled(false);
      saveRouterState(router);
      window.plausible(
        'Signup', {
          props: { provider: 'EmailAndPassword' },
        },
      );
      setVerifyEmailMessage(intl.formatMessage(messages.verifyEmail));
    },
    onError: (error, mutationOptions) => {
      setSubmitDisabled(false);
      if (error.message === 'Email has already been registered') {
        setRegisteredAs(mutationOptions?.variables?.email);
      }
    },
    context: {
      passErrors: ['Email has already been registered'],
    },
  });

  const registerSubmitHandler = useCallback(
    async (
      { email }: { email: string },
    ) => {
      setSubmitDisabled(true);
      onSubmit?.();
      if (!executeRecaptcha) {
        enqueueSnackbar('Captcha not loaded', { variant: 'error' });
        return;
      }
      const captchaResponse = await executeRecaptcha('register');
      handleUserRegistration(
        registerUser,
        {
          email,
          captchaResponse,
        },
        locale,
      );
    }, [executeRecaptcha, locale, registerUser, onSubmit],
  );

  const loginSubmitHandler = useCallback((
    { password, username }: { password: string; username: string },
  ) => {
    setSubmitDisabled(true);
    createSession({
      variables: {
        username,
        password,
      },
    });
  }, [createSession]);

  return (
    <EmailAccessForm
      registerSubmitHandler={registerSubmitHandler}
      loginSubmitHandler={loginSubmitHandler}
      registeredAs={registeredAs}
      forgotPasswordLink={registeredAs && (
        <LinkAndAnchor
          route="reset-password"
        >
          <Subtitle2 tag="span">
            {intl.formatMessage(messages.forgotPassword)}
          </Subtitle2>
        </LinkAndAnchor>
      )}
      emailLabel={intl.formatMessage(messages.emailAddress)}
      passwordLabel={intl.formatMessage(messages.password)}
      submitLabel={intl.formatMessage(messages.continue)}
      requiredValidationMessage={intl.formatMessage(messages[
        'applicantForm.fieldRequired'
      ])}
      emailValidationMessage={intl.formatMessage(messages[
        'applicantForm.mustBeValidEmailAddress'
      ])}
      submitDisabled={submitDisabled}
    />
  );
};

EmailAccess.propTypes = emailAccessPropertyTypes;

export default EmailAccess;
