import React, { ComponentProps } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Controller } from 'react-hook-form';

const hiddenInputWithControllerPropertyTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  registeredValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired),
  ]),
};

type HiddenInputWithControllerProperties = InferProps<
  typeof hiddenInputWithControllerPropertyTypes
> & {
  control: ComponentProps<typeof Controller>['control'];
};

const HiddenInputWithController = ({
  control,
  name,
  registeredValue,
}: HiddenInputWithControllerProperties) => (
  <Controller
    control={control}
    name={name}
    defaultValue={registeredValue}
    render={({
      value, ref,
    }: {
      // eslint-disable-next-line react/no-unused-prop-types
      value: string[] | string;
      // eslint-disable-next-line react/no-unused-prop-types
      ref: React.RefObject<HTMLInputElement>;
    }) => (
      <input name={name} type="hidden" value={value} ref={ref} />
    )}
  />
);

HiddenInputWithController.propTypes = hiddenInputWithControllerPropertyTypes;

export default HiddenInputWithController;
