import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useForm } from 'react-hook-form';
import { TextFieldWithController } from '../TextField';
import Button from '../Button';
import ValidationError from '../ValidationError';
import {
  getEmailValidation,
  getRequiredValidation,
} from '../lib/validationRules';
import HiddenInputWithController from '../Dashboard/HiddenInput';

const emailRegisterFormPropertyTypes = {
  submitHandler: PropTypes.func.isRequired,
  requiredValidationMessage: PropTypes.string.isRequired,
  emailValidationMessage: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
};

type EmailRegisterFormProperties = InferProps<
typeof emailRegisterFormPropertyTypes
>;

const EmailRegistrationForm = ({
  submitHandler,
  emailLabel,
  submitDisabled,
  submitLabel,
  emailValidationMessage,
  requiredValidationMessage,
}: EmailRegisterFormProperties) => {
  const {
    handleSubmit, errors, control,
  } = useForm({
    defaultValues: { email: '' },
  });

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="form-item">
        <TextFieldWithController
          control={control}
          name="email"
          rules={
            getEmailValidation(
              emailValidationMessage,
              requiredValidationMessage,
            )
          }
          variant="filled"
          id="register-email"
          label={emailLabel}
          type="email"
        />
        <ValidationError errors={errors} name="username" />
      </div>

      <Button
        fullWidth
        variant="contained"
        disabled={submitDisabled}
        className="account-login-submit"
        type="submit"
      >
        {submitLabel}
      </Button>
    </form>
  );
};

const loginFormPropertyTypes = {
  emailValue: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired,
  requiredValidationMessage: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  forgotPasswordLink: PropTypes.node,
};

type LoginFormProperties = InferProps<typeof loginFormPropertyTypes>;

const LoginForm = ({
  submitHandler,
  emailValue,
  passwordLabel,
  submitDisabled,
  submitLabel,
  requiredValidationMessage,
  forgotPasswordLink,
}: LoginFormProperties) => {
  const {
    handleSubmit, errors, control,
  } = useForm({
    defaultValues: {
      username: emailValue,
      password: '',
    },
  });

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="form-item">
        <TextFieldWithController
          control={control}
          name="password"
          rules={
            getRequiredValidation(
              requiredValidationMessage,
            )
          }
          variant="filled"
          id="login-password"
          type="password"
          label={passwordLabel}
        />
        { forgotPasswordLink }
        <ValidationError errors={errors} name="password" />
      </div>
      <HiddenInputWithController
        control={control}
        name="username"
        registeredValue=""
      />
      <Button
        fullWidth
        variant="contained"
        disabled={submitDisabled}
        className="account-login-submit"
        type="submit"
      >
        {submitLabel}
      </Button>
    </form>
  );
};

const emailAccessFormPropertyTypes = {
  registerSubmitHandler: PropTypes.func.isRequired,
  loginSubmitHandler: PropTypes.func.isRequired,
  requiredValidationMessage: PropTypes.string.isRequired,
  emailValidationMessage: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  registeredAs: PropTypes.string,
  forgotPasswordLink: PropTypes.node,
};

type EmailAccessFormProperties = InferProps<
typeof emailAccessFormPropertyTypes
>;

const EmailAccessForm = ({
  registerSubmitHandler,
  loginSubmitHandler,
  requiredValidationMessage,
  emailValidationMessage,
  emailLabel,
  passwordLabel,
  submitLabel,
  submitDisabled,
  registeredAs,
  forgotPasswordLink,
}: EmailAccessFormProperties) => (registeredAs ? (
  <LoginForm
    emailValue={registeredAs}
    submitHandler={loginSubmitHandler}
    passwordLabel={passwordLabel}
    submitDisabled={submitDisabled}
    submitLabel={submitLabel}
    requiredValidationMessage={requiredValidationMessage}
    forgotPasswordLink={forgotPasswordLink}
  />
) : (
  <EmailRegistrationForm
    submitHandler={registerSubmitHandler}
    emailLabel={emailLabel}
    submitDisabled={submitDisabled}
    submitLabel={submitLabel}
    emailValidationMessage={emailValidationMessage}
    requiredValidationMessage={requiredValidationMessage}
  />
));

EmailAccessForm.propTypes = emailAccessFormPropertyTypes;

export default EmailAccessForm;
