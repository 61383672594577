import { ReactNode, useEffect, useMemo } from 'react';
import useSeed from '../lib/useSeed';
import seededSampleSize from '../lib/seededSampleSize';

// code point of A
const startCodePoint = 65;

// gets the letter of the variant A, B, C, ...
const getVariantLetter = (
  variants: ReactNode[],
  variant: ReactNode,
) => String.fromCodePoint(startCodePoint + variants.indexOf(variant));

const ABTest = <T extends ReactNode | string>({
  name,
  variants,
  properties,
  onVariantChosen,
  getConversionTracker,
  getVariantName = getVariantLetter,
}: {
  name: string;
  variants: T[];
  properties?: Record<string, unknown>;
  onVariantChosen?: (variantLetter: string) => void;
  getConversionTracker?: (
    trackConversion: (
      properties?: Record<string, unknown>
    ) => void
  ) => void;
  getVariantName?: (variants: T[], variant: T) => string;
}) => {
  const seed = useSeed();
  const [variant] = useMemo(
    () => seededSampleSize(variants, 1, seed), [seed, variants],
  );

  useEffect(() => {
    window.plausible('ABTest', {
      props: {
        ...properties,
        name,
        variant: getVariantName(variants, variant),
      },
    });
  }, [name, properties, getVariantName, variant, variants]);

  useEffect(() => {
    onVariantChosen?.(getVariantName(variants, variant));
  }, [onVariantChosen, getVariantName, variant, variants]);

  useEffect(() => {
    getConversionTracker?.((conversionProperties?: Record<string, unknown>) => {
      window.plausible('ABTestConversion', {
        props: {
          ...properties,
          ...conversionProperties,
          name,
          variant: getVariantName(variants, variant),
        },
      });
    });
  }, [
    getConversionTracker,
    name,
    getVariantName,
    variant,
    variants,
    properties,
  ]);

  return variant;
};

export default ABTest;
