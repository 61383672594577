import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import ValidationError from '@fuww/library/src/ValidationError';
import loginWithTokenMutation from '../../lib/mutations/loginWithToken.graphql';
import registerWithTokenMutation
from '../../lib/mutations/registerWithToken.graphql';
import SocialLoginButton from './SocialLoginButton';

const LoginWithProviderForm = ({
  provider,
  onClick,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const client = useApolloClient();

  const onError = (error) => {
    setDisabled(false);
    setErrorMessage(error.message);
  };

  const [
    registerUserWithToken,
  ] = useMutation(registerWithTokenMutation, {
    throwOnError: true,
    onError,
  });

  const [
    createSession,
  ] = useMutation(loginWithTokenMutation, {
    onCompleted: (data) => {
      setDisabled(false);
      localStorage.setItem('accessToken', data.loginWithToken.accessToken);
      window.plausible('Login', {
        props: {
          provider,
          source: 'LoginPage',
        },
      });
      client.resetStore();
    },
    onError,
  });

  const onLogin = useCallback(() => {
    setDisabled(true);
    onClick();
  }, [setDisabled, onClick]);

  const onLoginSuccess = useCallback(async ({
    accessToken,
    providerArgument,
  }) => {
    const result = await registerUserWithToken({
      variables: { accessToken, provider: providerArgument },
    });
    if (result.data) {
      await createSession({
        variables: { accessToken, provider: providerArgument },
      });
    }
  }, [registerUserWithToken, createSession]);

  return (
    <>
      <SocialLoginButton
        labelKey="login.logInWithProvider"
        provider={provider}
        onLogin={onLogin}
        onLoginSuccess={onLoginSuccess}
        setErrorMessage={setErrorMessage}
        setDisabled={setDisabled}
        disabled={disabled}
      />
      { errorMessage
      && (
      <ValidationError>
        { errorMessage }
      </ValidationError>
      )}
    </>
  );
};

LoginWithProviderForm.propTypes = {
  provider: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

LoginWithProviderForm.defaultProps = {
  onClick: () => {},
};

export default LoginWithProviderForm;
