import React from 'react';
import PropTypes from 'prop-types';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

export const CaptchaProvider = ({ children }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LdqipYqAAAAAN-8R_mO0vAou3fYTrvyzcrG0y3z"
  >
    {children}
  </GoogleReCaptchaProvider>
);

CaptchaProvider.propTypes = {
  children: PropTypes.node,
};

CaptchaProvider.defaultProps = {
  children: undefined,
};

export { useGoogleReCaptcha as useCaptcha } from 'react-google-recaptcha-v3';
